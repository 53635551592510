import urlServices from '@/helpers/urlServices';
import { apiRequest } from '@/services/apiRequest';

const state = {};

const mutations = {};

const actions = {
  async handleRenameVideo({ commit }, payload) {
    return apiRequest(urlServices.renameRecordings, 'PUT', payload);
  },
  async handleMp3Convert({ commit }, payload) {
    return apiRequest(urlServices.convertToMp3, 'POST', payload);
  },
  async handleArchive({ commit }, payload) {
    return apiRequest(urlServices.archiveRecordings, 'PUT', payload);
  },
  async handleDownload({ commit }, fileName) {
    return apiRequest(urlServices.fetchVideo + fileName, 'GET');
  },
  async handleAccessType({ commit }, payload) {
    return apiRequest(urlServices.changeAccess, 'PUT', payload);
  },
  async handleCopyLink({ commit }, payload) {
    const baseUrl = process.env.VUE_APP_BASE_URL;
    let appUrl = `${baseUrl}/video/${payload?.access_type || 'public'}/${
      payload.fileName
    }`;
    if (payload.access_type === 'private' && payload.token) {
      appUrl += `?token=${payload.token}`;
    }
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(appUrl);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
