import urlServices from '@/helpers/urlServices';
import { apiRequest } from '@/services/apiRequest';

const state = {
  workspaces: [],
  currentWorkspace: null,
};

const mutations = {
  ADD_WORKSPACE(state, workspace) {
    if (workspace && workspace.id && workspace.name) {
      const exists = state.workspaces.some((ws) => ws.id === workspace.id);

      if (!exists) {
        state.workspaces.push(workspace);
      }
    } else {
      console.error('Invalid workspace data:', workspace);
    }
  },
  UPDATE_WORKSPACE_NAME(state, { oldName, newName }) {
    const workspace = state.workspaces.find((ws) => ws.name === oldName);
    if (workspace) {
      workspace.name = newName;
      if (state.currentWorkspace.name === oldName) {
        state.currentWorkspace.name = newName;
      }
    }
  },
  REMOVE_WORKSPACE(state, workspaceId) {
    const index = state.workspaces.findIndex((ws) => ws._id === workspaceId);
    if (index !== -1) {
      state.workspaces.splice(index, 1);
    }
  },
  SET_WORKSPACES(state, workspaces) {
    state.workspaces = workspaces;
  },
  setWorkspaceData(state, workspace) {
    state.currentWorkspace = workspace;
  },

  setWorkspaces(state, workspaces) {
    state.workspaces = workspaces;
  },
};

const actions = {
  async addWorkspace({ commit, state }, workspace) {
    if (workspace && workspace.id) {
      // Check if the workspace already exists
      const exists = state.workspaces.some((ws) => ws.id === workspace.id);

      if (!exists) {
        commit('ADD_WORKSPACE', workspace);
      }
    } else {
      this.error(error);
    }
  },
  updateWorkspaceName({ commit }, payload) {
    commit('UPDATE_WORKSPACE_NAME', payload);
  },
  removeWorkspace({ commit }, workspaceId) {
    commit('REMOVE_WORKSPACE', workspaceId);
  },

  currentWorkspace({ commit }, workspace) {
    commit('setWorkspaceData', workspace);
  },

  async fetchWorkspaces({ commit }) {
    try {
      await apiRequest(urlServices.getAllWorkSpaces, 'GET')
        .then((response) => {
          if (response.data && response.data?.data?.workspaces)
            commit('setWorkspaces', response.data?.data?.workspaces);
        })
        .catch((Error) => {
          console.log('ERROR in fetch Workspaces', Error);
        });
    } catch (error) {
      console.error('Error fetching workspaces:', error);
    }
  },
};

const getters = {
  allWorkspaces: (state) => state.workspaces,
  getWorkspace: (state) => state.currentWorkspace, // Add this getter
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
