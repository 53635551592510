import store from '@/store';

/**
 * Snackbar Plugin
 * @param {String} message - message to be shown in snackbar
 * @param {String} error - error to be shown in snackbar
 */
export default {
  install(Vue) {
    Vue.prototype.success = function (message) {
      store.dispatch('snackbarStore/showSnackbar', {
        type: 'success',
        message,
      });
    };

    Vue.prototype.error = function (error) {
      let message = null
      if(error) message = error
      if(error?.message) message = error?.message
      if(error?.response?.data?.message) message = error?.response?.data?.message
      store.dispatch('snackbarStore/showSnackbar', {
        type: 'error',
        message: message,
      });
    };
  },
};
