<template>
  <v-navigation-drawer
    class="sidebar-component"
    :permanent="$vuetify.breakpoint.lgAndUp"
    v-model="drawer.value"
    app
    width="230px"
  >
    <v-list-item class="px-2">
      <v-list-item-title class="d-flex justify-center">
        <div style="height: 77px">
          <router-link to="/saved">
            <v-img
              src="@/assets/app-logo.84f1d7b6.png"
              max-width="150px"
              contain
              class="py-2"
            ></v-img>
          </router-link>
        </div>
      </v-list-item-title>
    </v-list-item>

    <v-divider class="mx-5"></v-divider>

    <v-list rounded dense class="list-item-router mb-0 pb-0 mt-1">
      <ListItem v-for="item in items" :key="item.title" :item="item" />
    </v-list>

    <v-list rounded dense>
      <v-list-item class="mb-0">
        <v-list-item-title
          class="text--secondary d-flex align-center justify-space-between"
          >Spaces
          <v-tooltip content-class="tooltip-button" right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                @click="addWorkspaceValue.value = true"
                class="ml-2"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create a Workspace</span>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item>

      <!-- Browse Workspace Button -->
      <CreateWorkspaceModal
        :inputValues="newWorkspaceModelValue"
        :dialog="addWorkspaceValue"
        @saveWorkspace="addNewWorkspace"
      />

      <Workspaces :workspaces="allWorkspaces" />
    </v-list>

    <template v-slot:append>
      <v-divider class="mx-5"></v-divider>
      <v-list rounded dense>
        <v-list-item
          link
          class="select-list mt-1"
          @click="navigateToBrowseWorkspace"
        >
          <v-list-item-icon class="mr-4">
            <v-icon size="20" color="black">mdi-magnify</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-content>
              <span>Browse Workspaces</span>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-menu
        rounded="xl"
        transition="slide-x-transition"
        max-width="150"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list dense rounded class="mb-2">
            <v-list-item dense class="select-list" v-bind="attrs" v-on="on">
              <v-list-item-icon class="mr-4">
                <v-icon size="20" color="black"> mdi-account-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <span>
                  <TruncateText
                    :text="user.firstName + ' ' + user.lastName"
                    :maxLength="15"
                  >
                  </TruncateText
                ></span>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
        <v-card :elevation="12" class="rounded-xl" max-width="150">
          <v-list density="compact" class="py-0">
            <!-- User Settings Link -->

            <ListItem
              :item="{
                title: 'User Settings',
                icon: 'mdi-cog-outline',
                route: '/user-settings',
              }"
            />
            <ListItem
              @onClick="handleLogout"
              :item="{
                title: 'Sign Out',
                icon: 'mdi-logout',
              }"
            />
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import CreateWorkspaceModal from '@/layout/workspace/CreateWorkspaceModal.vue';
import Workspaces from '@/layout/workspace/WorkSpaces.vue';
import rules from '@/helpers/rules';
import TruncateText from '@/components/common/TruncateText.vue';
import user from '@/store/modules/users/user';
import apiRequest from '@/services/apiRequest';
import urlServices from '@/helpers/urlServices';

export default {
  components: {
    CreateWorkspaceModal,
    Workspaces,
    TruncateText,
  },
  props: {
    drawer: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      items: [
        {
          title: 'Saved Recording',
          icon: 'mdi-content-save-outline',
          route: '/saved',
        },
      ],
      mini: true,
      addWorkspaceValue: { value: false },
      newWorkspaceModelValue: {
        value: '',
        label: 'Name',
        type: 'text',
        rules: rules.nameRule,
      },
    };
  },
  mounted() {
    this.fetchWorkspaces();
  },
  computed: {
    ...mapGetters('workspaces', ['allWorkspaces']),
    ...mapGetters('userStore', ['user']),
  },

  methods: {
    ...mapActions('workspaces', ['fetchWorkspaces']),

    async handleLogout() {
      try {
        await this.apiRequest(urlServices.logout, 'POST')
          .then((response) => {
            this.success(response.data.message);
            this.$store.dispatch('userStore/setToken', null);
            localStorage.clear();
            this.$router.push('/login');
          })
          .catch((error) => {
            this.error(error);
          })
      } catch (error) {
        this.error(error)
      }
    },
    addNewWorkspace() {
      this.fetchWorkspaces();
    },
    navigateToBrowseWorkspace() {
      const targetRoute = '/browse-workspace';
      if (this.$route.path !== targetRoute) {
        this.$router.push(targetRoute).catch((err) => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-navigation-drawer {
  background-color: #f7f7f8;
}

.v-list--rounded {
  padding: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.workspace-modal-container {
  max-height: 50%;
  overflow-y: auto;
}

.workspaces-container {
  flex-grow: 1;
  overflow-y: auto;
}

.v-navigation-drawer {
  overflow-y: hidden;
}

.select-list span {
  font-size: 12px;
  font-weight: 550;
}

.v-list-item__content {
  padding: 0;
}
</style>
<style>
.v-menu__content {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
</style>
