var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"pa-0 overflow-y-auto",attrs:{"max-height":"535","rounded":"","dense":""}},[_vm._l((_vm.workspaces),function(workspace){return _c('v-list-item',{key:workspace._id,class:{
      'workspace-item': true,
      'active-workspace': _vm.$route.params.workspaceId === workspace.id,
    },attrs:{"link":"","active-class":"list-active"},on:{"click":function($event){return _vm.workspaceClicked(workspace)}}},[_c('v-list-item-icon',{staticClass:"pb-3 mr-2"},[_c('v-avatar',{style:({ backgroundColor: '#2eb086', color: 'white' }),attrs:{"size":"28px"}},[_vm._v(" "+_vm._s(workspace && workspace.name.charAt(0).toUpperCase())+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{class:{
          'workspace-title': true,
          'active-title': _vm.selectedWorkspace === workspace.name,
        }},[_c('TruncateText',{attrs:{"text":workspace.name,"max-length":15}})],1)],1),(workspace.status === 'private')?_c('v-list-item-action',[_c('v-icon',{staticClass:"lock-icon",attrs:{"small":""}},[_vm._v("mdi-lock")])],1):_vm._e()],1)}),_c('DeleteConfirmationModal',{attrs:{"dialog":_vm.isDeleteDialogVisible,"deleteType":"this Workspace?","loading":_vm.deleteWorkspaceLoading},on:{"confirmDelete":_vm.deleteWorkspace}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }