<template>
  <div>
    <v-dialog v-model="dialog.value" max-width="480px" persistent>
      <v-card rounded="xl" class="pb-2">
        <v-card-title>
          <span class="text-subtitle-2 font-weight-bold text-capitalize">
            Create New Workspace
          </span>
          <v-spacer></v-spacer>

          <Button
            @click="dialog.value = false"
            :btn="{
              type: 'button',
              customClass: '',
              block: false,
              icon: 'mdi-close',
            }"
          />
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="saveWorkspace">
            <InputField
              :disabled="loading"
              :props="inputValues"
              :outlined="true"
              @enter="saveWorkspace"
            ></InputField>

            <v-radio-group v-model="privacy" class="mt-0">
              <v-radio
                value="open"
                active-class="workspace-radio-active"
                class="mb-2 workspace-modal-radio pa-3 d-flex align-start"
              >
                <template v-slot:label>
                  <div style="color: black">
                    <p class="fs-14 fw-600 mb-0">Open</p>
                    <p class="caption mb-0">Anyone can Join</p>
                  </div>
                </template>
              </v-radio>
              <v-radio
                value="closed"
                active-class="workspace-radio-active"
                class="mb-2 workspace-modal-radio pa-3 d-flex align-start"
              >
                <template v-slot:label>
                  <div style="color: black">
                    <p class="fs-14 fw-600 mb-0">Closed</p>
                    <p class="caption mb-0">Only invited people can join</p>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <Button
            @click="closeModal"
            :btn="{
              type: 'submit',
              customClass: 'mt-2',
              label: 'Cancel',
              block: false,
            }"
          />

          <Button
            @click="saveWorkspace"
            :loading="loading"
            :disabled="!isFormValid"
            :btn="{
              type: 'submit',
              color: 'primary',
              customClass: 'mt-2',
              label: 'Create',
              block: false,
            }"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import urlServices from '@/helpers/urlServices';
import rules from '@/helpers/rules';

import { mapActions } from 'vuex';
export default {
  props: {
    dialog: {
      default: {},
      type: Object,
    },
    inputValues: { default: {}, type: Object },
  },
  data() {
    return {
      dialogVisible: false,
      workspaceName: '',
      members: '',
      privacy: 'open',
      emailRule: rules.emailRule,
      loading: false,
    };
  },
  computed: {
    /**
     * Checks if the form is valid based on the values of workspaceName, members, and privacy.
     * @returns {boolean} True if all fields are filled, false otherwise.
     */
    isFormValid() {
      return (
        this.inputValues.value.trim() !== '' &&
        // this.members.trim() !== "" &&
        this.privacy !== ''
      );
    },
  },
  methods: {

    /**
     * Displays the modal by setting dialogVisible to true.
     */

    showModal() {
      this.dialogVisible = true;
    },
    /**
     * Closes the modal by setting dialogVisible to false, and resets the form fields.
     * Also resets form validation state.
     */
    closeModal() {
      this.dialog.value = false;
      this.inputValues.value = '';
      this.members = '';
      this.privacy = 'open';
      this.$refs.form.resetValidation();
    },

    /**
     * Creates a new workspace and updates the Vuex store.
     *
     * Sends a POST request with workspace data if the form is valid and not loading.
     * Handles token retrieval, API request, and error logging.
     *
     * @async
     * @returns {Promise<void>}
     */
    async saveWorkspace() {
      if (this.isFormValid && !this.isLoading) {
        this.loading = true;
        try {
          const workspaceData = {
            name: this.inputValues.value,
            status:
              this.privacy.toLowerCase() === 'closed' ? 'private' : 'public',
          };

          await this.apiRequest(
            urlServices.createWorkSpace,
            'POST',
            workspaceData
          )
            .then((response) => {
              this.$emit('saveWorkspace');
              this.success(response.data.message);
              this.inputValues.value = '';
              this.$refs.form.resetValidation();
              this.dialog.value = false;
            })
            .catch((Error) => {
              this.error(Error);
            })
            .finally(() => {
              this.loading = false;
            });
        } catch (error) {
          this.error(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-width {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.select-list span {
  font-size: 12px;
  font-weight: 550;
}

.workspace-modal-radio:deep(.v-input--selection-controls__input) {
  font-size: 12px;
}
.workspace-modal-radio:deep(.v-input--selection-controls__input i) {
  font-size: 20px;
}
.workspace-modal-radio {
  border: 1px solid rgba(202, 202, 202, 0.272);
  border-radius: 12px;
}
.workspace-modal-radio:hover {
  border-color: #4a5854a0;
}
.workspace-radio-active {
  border-color: #2db086;
  background: rgba(238, 238, 238, 0.402);
}
</style>
